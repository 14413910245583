

import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '/binApi',
    // 超时
    timeout: 80 * 1000
})


// 如果你的 API 需要认证，这里可以设置默认的认证头信息
// axios.defaults.headers['Authorization'] = '';

// 请求拦截器，比如添加 token
service.interceptors.request.use(config => {
    // 在发送请求之前做些什么，例如添加 Token
    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 响应拦截器，处理响应数据，如错误统一处理
service.interceptors.response.use(response => {
    return response.data;
}, error => {
    // 处理错误情况，例如弹出错误提示等
    return Promise.reject(error);
});
export default service