import request from '@/utils/request'
// 获取集群信息
export function getClusterInfo(params) {
  return request({
    url: '/cluster-info',
    method: 'get',
    params
  })
}

// 上方整体概览
export function getIndexMain() {
  return request({
    url: '/cluster-info/index-main',
    method: 'get',
    
  })
}
// 整体资源利用率
export function getGlobalHistoricalUtilisation(params) {
  return request({
    url: '/cluster-info/global-historical-utilisation',
    method: 'get',
    params
  })
}


// 整体任务状态
export function getGlobalState(params) {
  return request({
    url: '/cluster-info/task-index/global-state',
    method: 'get',
    params
  })
}


// 实时利用率
export function getRealTimeUtilisation(params) {
  return request({
    url: '/cluster-info/real-time-utilisation',
    method: 'get',
    params
  })
}

// 实时利用率
export function getHistoricalUtilisation(params) {
  return request({
    url: '/cluster-info/historical-utilisation',
    method: 'get',
    params
  })
}

// 节点状态
export function getNodeState(params) {
  return request({
    url: '/cluster-info/node-state',
    method: 'get',
    params
  })
}

// 任务状态
export function getTakeState(params) {
  return request({
    url: '/cluster-info/task-index/state',
    method: 'get',
    params
  })
}


// 节点列表
export function getNodeIndex(params) {
  return request({
    url: '/cluster-info/node-index',
    method: 'get',
    params
  })
}

// 首页任务列表
export function getTaskIndex(id) {
  const str = id.toLowerCase()
  return request({
    url: `/cluster-info/task-index/${str}`,
    method: 'get',
  })
}



// 详情


// 详情节点任务列表
export function getNodeDetail(id) {
  const str = id.toLowerCase()
  return request({
    url: `/cluster-info/node-detail/${str}`,
    method: 'get',
  })
}

// 详情任务状态
export function getTaskDetail(id) {
  const str = id.toLowerCase()
  return request({
    url: `/cluster-info/task-detail/${str}`,
    method: 'get',
  })
}



// 详情页节点CPU利用率
export function getTaskDetailCPU(params) {
  return request({
    url: `/cluster-info/node-detail/cpu`,
    method: 'get',
    params
  })
}


// 详情页gpu节点利用率计算数据
export function getTaskDetialCPUMore(params) {
  return request({
    url: `/cluster-info/node-detail/cpu-more`,
    method: 'get',
    params
  })
}



// 详情页mem节点利用率
export function getTaskDetailMEM(params) {
  return request({
    url: `/cluster-info/node-detail/mem`,
    method: 'get',
    params
  })
}

// 详情页mem节点利用率计算数据
export function getTaskDetialMEMMore(params) {
  return request({
    url: `/cluster-info/node-detail/mem-more`,
    method: 'get',
    params
  })
}

// 详情页gpu节点利用率
export function getTaskDetailGPU(params) {
  return request({
    url: `/cluster-info/node-detail/gpu`,
    method: 'get',
    params
  })
}


// 详情页mem节点利用率计算数据
export function getTaskDetialGPUMore(params) {
  return request({
    url: `/cluster-info/node-detail/gpu-more`,
    method: 'get',
    params
  })
}






